(() => {
    const storage = window.localStorage;
    const storageKey = 'uriHistory';
    const itemKeepInHistory = 2;
    const currentHost = window.location.protocol + '//' + window.location.hostname;
    let history = storage.getItem(storageKey);
    history = history ? JSON.parse(history) : [];
    history.push(window.location.pathname + window.location.search + window.location.hash);

    if (history.length > itemKeepInHistory) {
        history = history.slice(itemKeepInHistory - 1, history.length)
    }

    storage.setItem('uriHistory', JSON.stringify(history));

    document.querySelectorAll('[data-history-paramaters]').forEach((linkElement) => {
        if (!linkElement.hasAttribute('href')) {
            return;
        }
        const href = new URL(linkElement.getAttribute('href'), currentHost);
        let foundHistoryItem = false;

        for(let i = history.length - 1; i >= 0;i--) {
            let currentHistoryItem = new URL(history[i], currentHost);
            console.log(currentHistoryItem, href);
            if (currentHistoryItem.pathname === href.pathname) {
                linkElement.setAttribute('href', history[i])
                foundHistoryItem = true
                break;
            }
        }

        if (!foundHistoryItem) {
            linkElement.classList.add('hidden')
        }
    })
})()
