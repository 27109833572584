(() => {
    const links = document.querySelectorAll('.text-link, .text-link-highlight, .button')

    if (links.length <= 0) {
        return
    }

    const linkTracker = (event) => {
        const targetLink = event.target

        window.dataLayer && window.dataLayer.push({
          'event': 'genericEvent',
          'eventCategory': 'Button',
          'eventAction': 'click',
          'eventLabel': targetLink.textContent || targetLink.innerText,
          'eventTarget': targetLink.getAttribute('href'),
        })
    }

    links.forEach(link => {
        link.addEventListener('click', linkTracker)
    })
})()
