import debounce from "lodash.debounce";

(() => {
	class DownloadTableComponent {
		constructor (element) {
			this.filterInput = element.querySelector (".download-table__filter");
			this.brandSelect = element.querySelector (".download-table__brand");
			this.typeSelect = element.querySelector (".download-table__type");
			
			this.table = element.querySelector (".download-table__table");
			this.noResultsMessage = element.querySelector (".download-table__no_results");
			
			this.tableRows = this.table.querySelectorAll ("tbody tr");
			
			this.indexTableColumns ();
			this.registerEventHandlers ();
			
			this.filterRows ();
			
		}
		
		static searchKeys = ["productTitle"];
		
		static typeActiveClasses = ["bg-primary", "border-primary", "active"];
		static searchDebounceMs = 100;
		
		indexTableColumns () {
			this.tableRows.forEach (row => {
				const dataset = row.dataset;
				const data = row.data = {
					brand: dataset.brand?.trim (),
					productTitle: dataset.productTitle?.trim (),
					types: []
					
				};
				
				const anchors = data.anchors = row.querySelectorAll ("a");
				anchors.forEach (anchor => data.types.push (anchor.dataset.typeLabel));
				
			})
			
		}
		
		registerEventHandlers () {
			this.filterInput?.addEventListener ("input", debounce (this.onSearchInput.bind (this), DownloadTableComponent.searchDebounceMs));
			
			this.brandSelect?.querySelectorAll ("li").forEach (
				li => li.addEventListener ("click", this.onBrandFilterClick.bind (this))
				
			);
			this.typeSelect?.querySelectorAll ("li").forEach (
				li => li.addEventListener ("click", this.onTypeFilterClick.bind (this))
				
			);
			
		}
		
		getCurrentTypeLabel () {
			let node = this.typeSelect?.querySelector (".active");
			if (!node || (node && node.dataset.filterValue == "*"))
				return undefined;
			
			return node.dataset.filterValue;
			
		}
		
		getCurrentBrand () {
			let node = this.brandSelect?.querySelector (".active")
			if (!node || (node && node.dataset.filterValue == "*"))
				return undefined;
			
			return node.dataset.filterValue;
			
		}
		
		onSearchInput () {
			const searchTerm = this.filterInput.value?.trim ();
			const usesSearch = this.usesSearch = searchTerm.length > 0;
			
			if (usesSearch) {
				this.table.parentNode.classList.remove ("hidden");
				
				const searchRegexes = searchTerm.split (" ").map (term => new RegExp (term, "ig"))
				const matchSearchTermsInProperties = (row, keys) => {
					const data = row.data;
					for (let regex of searchRegexes) {
						for (let property of keys) {
							if (data [property].search (regex) != -1) {
								row.matchesSearch = true;
								return;
								
							}
							
						}
						
					}
					row.matchesSearch = false;
					
				}
				this.tableRows.forEach (row => matchSearchTermsInProperties (row, DownloadTableComponent.searchKeys));
				
			}
			
			this.filterRows ();
			
		}
		
		onBrandFilterClick ({currentTarget}) {
			if (currentTarget.classList.contains ("active"))
				return;
			
			const currentTypeNode = this.brandSelect.querySelector (".active");
			if (currentTypeNode)
				DownloadTableComponent.typeActiveClasses.forEach (
					cssClass => currentTypeNode.classList.remove (cssClass)
					
				);
			
			DownloadTableComponent.typeActiveClasses.forEach (
				cssClass => currentTarget.classList.add (cssClass)
				
			);
			
			this.filterRows ();
			
			this.typeSelect?.parentNode.classList.remove ("hidden");
			
		}
		
		onTypeFilterClick ({currentTarget}) {
			if (currentTarget.classList.contains ("active"))
				return;
			
			const currentTypeNode = this.typeSelect.querySelector (".active");
			if (currentTypeNode)
				DownloadTableComponent.typeActiveClasses.forEach(
					cssClass => currentTypeNode.classList.remove (cssClass)
					
				);
			
			DownloadTableComponent.typeActiveClasses.forEach (
				cssClass => currentTarget.classList.add (cssClass)
				
			);
			
			this.filterRows ();
			
			this.table.parentNode.classList.remove ("hidden");
			
		}
		
		filterRows () {
			const usesSearch = this.usesSearch;
			const brand = this.getCurrentBrand ();
			const typeLabel = this.getCurrentTypeLabel ();
			
			let numHits = 0;
			
			function addCssClasses (element, cssClassesString) {
				element && element.classList.add (...cssClassesString.split (" "));
				
			}
			function removeCssClasses (element, cssClassesString) {
				element && element.classList.remove (...cssClassesString.split (" "));
				
			}
			
			this.tableRows.forEach (row => {
				let doesNotMatchSearch = usesSearch && row.matchesSearch === false;
				
				let isNotCorrectType = typeLabel && !row.data.types.includes (typeLabel);
				let isNotCorrectBrand = brand && row.dataset.brand?.trim () != brand;
				
                if (doesNotMatchSearch || isNotCorrectBrand || isNotCorrectType) {
                    addCssClasses (row, "md:hidden hidden");
                    
                } else {
					let anchors = row.data.anchors;
					anchors.forEach (anchor => {
						let isNotCorrectType = typeLabel && anchor.dataset.typeLabel != typeLabel;
						if (isNotCorrectType)
							anchor.classList.add ("hidden");
						else
							anchor.classList.remove ("hidden");
						
					});
					
                    removeCssClasses (row, "md:hidden hidden");
                    numHits++;
                    
                }
                
			});
			
			const noResultsMessage = this.noResultsMessage;
			if (noResultsMessage) {
				const table = this.table;
				if (numHits) {
					noResultsMessage.classList.add ("hidden");
					table.style.display = "";
					
				} else {
					noResultsMessage.classList.remove ("hidden");
					table.style.display = "none";
					
				}
				
			}
			
		}
		
	}
	
	document.querySelectorAll (".download-table").forEach (element => {
		if (element.querySelector (".download-table__filter_container"))
			new DownloadTableComponent (element);
		
	});
	
}) ();
