(() => {
    class SimpleFilter {
        constructor(component) {
            const items = document.querySelectorAll(component.dataset.filterTargetElements)
            if (!items.length)
				return;
			
            this.items = items
            this.filterContainer = component
            this.filterButtons = component.querySelectorAll('li')
            this.filterNoneButton = component.querySelector('[data-filter-value="*"]')
            this.filterName = component.dataset.filterName
            this.activeClasses = [ ...this.filterContainer.dataset.filterActiveClass.split(' '), 'active']
            this.filterValues = []

            this.registerEventHandlers()
            this.highlightAnchorFilter()
        }

        highlightAnchorFilter() {
            let anchor = window.location.hash.substr(1)
            let anchorFound = document.getElementById(anchor) !== null

            if (!anchorFound) return;

            document.getElementById(anchor).click()
        }

        registerEventHandlers() {
            this.filterButtons.forEach(button => {
                button.addEventListener('click', this.onFilterButtonClick.bind(this))
            })
        }

        /**
         * @param event MouseEvent
         */
        onFilterButtonClick(event) {
            const clickedButton = event.currentTarget

            if (this.filterNoneButton === clickedButton) {
                this.filterButtons.forEach(button => {
                    if (this.isActive(button)) this.toggleActiveState(button)
                })
                this.toggleActiveState(this.filterNoneButton)
                this.filterValues = []
                this.filterItems()

                return
            }

            if (this.isActive(this.filterNoneButton)) {
                this.toggleActiveState(this.filterNoneButton)
            }

            this.toggleActiveState(clickedButton)

            if (!this.hasActiveButton()) {
                this.toggleActiveState(this.filterNoneButton)
            }

            this.filterItems()
        }

        isActive(button) {
            return button.classList.contains('active')
        }

        hasActiveButton() {
            for (const button of this.filterButtons) {
                if (this.isActive(button)) return true
            }
            return false
        }

        toggleActiveState(button) {
            for (const cssClass of this.activeClasses) {
                button.classList.toggle(cssClass)
            }

            const filterValue = button.dataset.filterValue;

            if (filterValue === '*') return

            if (this.filterValues.indexOf(filterValue) !== -1) {
                this.filterValues.splice(this.filterValues.indexOf(filterValue), 1)
            } else {
                this.filterValues.push(filterValue)
            }
        }

        filterItems() {
            const dataAttrName = this.filterName + 'FilterValue'

            this.items.forEach(item => {
                let filterValue = undefined

                if (item.dataset[dataAttrName]) {
                    filterValue = item.dataset[dataAttrName]
                } else {
                    filterValue = item.querySelector('[' + this.filterName + '-filter-value]').dataset[dataAttrName]
                }

                if (this.filterValues.length > 0 && this.filterValues.indexOf(filterValue) === -1) {
                    item.style.display = 'none'
                } else {
                    item.style.display = ''
                }
            })
        }
    }

    document.querySelectorAll('.filter-values').forEach(component => {
        new SimpleFilter(component)
    })
})()
